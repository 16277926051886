<button class="btn btn-info mt-4" (click)="openAnswersFilterModal(card)">
  Question Filter(s)
</button>

<ng-template #card>
  <div class="card">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="card-header">
      <h5>Define Filter</h5>
      <div class="card-actions">
        <a (click)="modalRef.hide()" style="color: #666" class="card-action card-action-dismiss"></a>
      </div>
    </div>
    <div class="card-body">
      <div class="filterWrapper">
        <div class="filterButton">
          <button class="btn btn-primary mt-2 mb-2" (click)="onShowAddQuestionsModal(questionSelector)">
            Add Question
          </button>
        </div>
        <div class="filterRadio">
          <label>Filter By Condition : </label>
          <label class="ml-3">
            <input type="radio" [(ngModel)]="isAllQuestionFilter" [value]="true" name="isAllQuestionFilter" (ngModelChange)="onChangeMode($event)">
            <strong> Is All Of</strong>
          </label>
          <label class="ml-2">
            <input type="radio" [(ngModel)]="isAllQuestionFilter" [value]="false" name="isAllQuestionFilter" (ngModelChange)="onChangeMode($event)">
            <strong> Is Any Of</strong>
          </label>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" *ngFor="let _question of filterQuestions">
          <div class="alert alert-default nomargin">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close"
              (click)="onRemoveQuestionFromFilter(_question)">
              <span aria-hidden="true">&times;</span>
            </button>
            <h6>{{ _question.questionText }}</h6>
            <div class="row">
              <div *ngFor="let item of _question.items">
                <ng-container *ngIf="
                    _question.questionType === _questionType.Boolean ||
                    _question.questionType === _questionType.SingleSelect
                  ">
                  <div class="col-auto">
                    <div class="radio-custom radio-primary">
                      <input [value]="item.itemValue" [formControl]="item.formControl"
                        name="singleSelect-{{ _question.uuid }}" type="radio" />
                      <label [for]="item.itemValue">{{ item.itemTitle }}</label>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="
                    _question.questionType === _questionType.MultiSelect &&
                    !item.disabled
                  ">
                  <div class="col-auto">
                    <div class="checkbox-custom chekbox-primary">
                      <input (change)="
                          onMultiSelectValueChange(_question, item, $event)
                        " name="multiSelect-{{ _question.uuid }}" type="checkbox" [formControl]="item.formControl" />
                      <label [for]="item.itemValue">{{ item.itemTitle }}</label>
                    </div>
                  </div>
                </ng-container>
              </div>
              <div class="form-group" style="width: 100%; padding-left: 15px; padding-right: 15px"
                *ngIf="isTextOrDateQuestion(_question)">
                <ng-container *ngIf="!_question.disabled">
                  <div class="row">
                    <label class="col-md-2" for="operator">Answer</label>
                    <div class="col-md-4">

                      <select id="operator" required class="form-control" [formControl]="_question.operatorFormControl"
                        name="operatorFormControl">
                        <option
                          *ngFor="let item of (TextOperator | enumToArray | filterByCondition:filterOperators:thisComponentInstance:_question)"
                          [ngValue]="TextOperator[item]">
                          {{ TextOperator[item] | textOperator }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6" *ngIf="
                        [TextOperator.isNull, TextOperator.isNotNull].indexOf(
                          _question.operatorFormControl?.value
                        ) == -1">

                      <div class="flex flex-column">
                        <input [formControl]="_question.formControl" type="text" class="form-control
{{ !_question.formControl?.value || _question.formControl?.value == 'Invalid Date' ? 'ng-invalid' : 'ng-valid'}}"
                          *ngIf="isTextQuestion(_question)" />

                        <obc-input-date-time-picker [value]="_question.formControl.value"
                          *ngIf="isDateQuestion(_question)" [formControl]="_question.formControl">
                        </obc-input-date-time-picker>
                        <span class="ml-auto"
                          *ngIf="!_question.formControl?.value || _question.formControl?.value == 'Invalid Date'"
                          style="font-size: 12px; color: red">This input field is required</span>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button class="btn btn-danger pull-right" [disabled]="!canCloseModal()" (click)="modalRef.hide()">
        {{ this.filterQuestions?.length > 0 ? 'Update' : 'Close' }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #questionSelector>
  <app-question-selector [showAddButton]="false" [isMultiSelect]="false" [companyQuestions]="questions"
    [addedQuestions]="getAddedQuestions()" (addQuestion)="addQuestion($event)" (hide)="onCloseAddQuestionsModal()">
  </app-question-selector>
</ng-template>